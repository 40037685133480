<template>
  <div class="record" v-show="modalShow">
    <div class="bg" @click="modalShow = false"></div>
    <div class="lottery-rules">
      <img style="width: 210px;height: 104px;" src="../../static/images/lottery_result_top1.png">
      <div class="lottery-box">
        <div class="rule-content" style="padding-top: 72px">
          太可惜了，与奖品擦肩而过
        </div>
        <div class="btm-btn" @click="modalShow = false">
          我知道了
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalShow: false,
      receiveName: '',
      qrCode:'',
      redeemCode:''
    }
  },
  methods: {
    show() {
      // this.receiveName = data.receiveName
      // this.qrCode = data.receiveQr
      // this.redeemCode = data.receiveCode
      this.modalShow = true
    }
  }
}
</script>

<style scoped lang="less">
.record {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;

  .bg {
    width: 100vw;
    height: 100vh;
    background-color: #00000094;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .lottery-rules {
    width: 238px;
    height: auto;
    margin-top: 20px;
    //display: flex;
    //justify-content: center;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    text-align: center;

    .lottery-box {
      width: 100%;
      margin-top: -40px;
      height: auto;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../../static/images/fail_bg.png");
      text-align: center;
      padding-bottom: 25px;

      .rule-content {
        height: auto;
        margin: 0 auto;
        padding: 12px 20px 25px 20px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #BC6201;
        line-height: 20px;
      }

      .btm-btn {
        margin: 0 auto;
        width: 137px;
        height: 32.5px;
        line-height: 32.5px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../static/images/btm-btn.png");
        text-align: center;
        font-size: 11px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #BC6201;
      }
    }
  }
}
</style>
