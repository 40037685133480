<template>
  <div class="record" v-show="modalShow">
    <div class="bg" @click="modalShow = false"></div>
    <div class="lottery-rules">
      <img style="width: 100%;height: 158px;" src="../../static/images/lottery_result_top.png">
      <div class="lottery-box">
        <div class="qc-code" v-if="!redeemCode" :style="{'padding-top':!redeemCode?'62px':'12px'}">
          <img :src="qrCode" height="100" width="100"/>
        </div>
        <div class="rule-content" :style="{'padding-top':redeemCode?'62px':'12px'}">
          {{ receiveName }}
        </div>
        <div class="rule-content"  v-if="redeemCode">
          兑换码：<span>{{ redeemCode }}</span>
        </div>
        <div class="rules-desc">{{ defaultData }}~</div>
        <div class="btm-btn" @click="modalShow = false">
          我知道了
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalShow: false,
      receiveName: '',
      qrCode:'',
      redeemCode:'',
      defaultData:''
    }
  },
  methods: {
    show(data) {
      this.receiveName = data.receiveName
      this.qrCode = data.receiveQr
      this.redeemCode = data.receiveCode
      this.defaultData = data.description
      this.modalShow = true
    }
  }
}
</script>

<style scoped lang="less">
.record {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;

  .bg {
    width: 100vw;
    height: 100vh;
    background-color: #00000094;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .lottery-rules {
    width: 265px;
    height: auto;
    margin-top: 20px;
    //display: flex;
    //justify-content: center;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;

    .lottery-box {
      //width: 282px;
      margin: 0 21px 0 21px;
      margin-top: -55px;
      height: auto;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../../static/images/rules_bg.png");
      text-align: center;
      padding-bottom: 25px;

      .rule-content {
        height: auto;
        margin: 0 auto;
        padding: 12px 20px 25px 20px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #BC6201;
        line-height: 20px;
      }

      .rules-desc {
        height: auto;
        margin: 0 auto;
        padding: 0px 20px 25px 20px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #BC6201;
        line-height: 20px;
      }

      .btm-btn {
        margin: 0 auto;
        width: 137px;
        height: 32.5px;
        line-height: 32.5px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../static/images/btm-btn.png");
        text-align: center;
        font-size: 11px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #BC6201;
      }
    }
  }
}
</style>
