<template>
    <div class="record" v-show="modalShow">
        <div class="bg" @click="modalShow = false"></div>
        <div class="lottery-rules">
            <img style="width: 100%;height: 158px;margin-bottom: -1px" src="../../static/images/rule_top.png">
            <div class="lottery-box">
              <div class="title">
                <img style="width: 72px;height: 48.5px;margin-top: 8px" src="../../static/images/title-left.png">
                <div class="title-text">活动说明</div>
                <img style="width: 72px;height: 48.5px;margin-top: 8px" src="../../static/images/title-right.png">
              </div>
              <div class="rule-content">
                {{lottery.description}}
              </div>
              <div class="title">
                <img style="width: 72px;height: 48.5px;margin-top: 8px" src="../../static/images/title-left.png">
                <div class="title-text">活动时间</div>
                <img style="width: 72px;height: 48.5px;margin-top: 8px" src="../../static/images/title-right.png">
              </div>
              <div class="rule-time" v-if="lottery.timeType===1">
                永久有效
              </div>
              <div class="rule-time" v-else>
                {{ lottery.startTime }}——{{ lottery.endTime }}
              </div>
              <div class="btm-btn" @click="modalShow = false">
                      我知道了
              </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalShow: false,
            lottery: {}
        }
    },
    methods: {
        show(lottery) {
            this.lottery = lottery
            this.modalShow = true
        }
    }
}
</script>

<style scoped lang="less">
.record {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;

    .bg {
        width: 100vw;
        height: 100vh;
        background-color: #00000094;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .lottery-rules{
      width: 337px;
      height: auto;
      margin-top: 20px;
      //display: flex;
      //justify-content: center;
      position: fixed;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 20;

      .lottery-box {
        //width: 282px;
        margin: 0 27.5px 0 28px;
        height: auto;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../static/images/rules_bg.png");
        text-align: center;
        padding-bottom: 50px;

        .title {
          margin: 0 auto;
          margin-bottom: -20px;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          .title-text {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #BC6201;
            margin: 0 20px;
          }
        }


        .rule-content {
          width: 235px;
          height: auto;
          margin: 0 auto;
          margin-bottom: 25px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #BC6201;
          line-height: 20px;
        }

        .rule-time {
          margin-bottom: 22px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #BC6201;
          line-height: 20px;
        }

        .btm-btn {
          margin: 0 auto;
          width: 210px;
          height: 50px;
          line-height: 50px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url("../../static/images/btm-btn.png");
          text-align: center;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #BC6201;
        }
      }
}   }
</style>
