<template>
  <div class="page">
    <div class="explain">
      <img style="width: 20px;height: 20px" src="../../static/images/red_qi.png">
      <div @click="$refs.explain.show(clientData.lottery)">活动规则</div>
    </div>
    <div class="lottery-draw">
      <div class="lottery-box">
        <div>
          <div class="lottery-title" v-if="corp.name">{{ corp.name }}</div>
          <div v-if="corp.description" class="lottery-desc" style="margin-bottom: 12px;margin-top: 23.5px">
            {{corp.description}}
          </div>
        </div>
        <div v-if="!corp.name && !corp.description">
          <img style="width: 336px;height: 117.5px;margin-bottom: 12px;margin-top: 23.5px"
               src="../../static/images/lottery_title.png">
        </div>
        <LuckyWheel
            style="width: 337px; height: 337px"
            ref="LuckyWheel"
            :prizes="luckDraw.prizes"
            :defaultConfig="luckDraw.defaultConfig"
            :blocks="luckDraw.blocks"
            :buttons="luckDraw.buttons"
            @start="startCallBack"
        />
        <a-carousel autoplay dots="true" dotPosition="left" class="lottery-namelist" v-if=" clientData.message!==undefined ">
          <div v-for="item in 3" class="lottery-name">恭喜<span>{{ clientData.message.nickname }}</span>获得{{clientData.message.prize_name}}</div>
        </a-carousel>
        <div style="width: 328px" v-if="clientData.win_list.length > 0">
          <div class="lottery-btm-top" style="margin-bottom: -9px">
          </div>
          <div class="lottery-btm">
            <div style="width: 136px;height: 55px;margin: 0 auto">
              <img style="width: 136px;height: 55px;" src="../../static/images/lottery_history.png">
            </div>
            <div class="lottery-history">
              <div class="lottery-history-item" v-for="(item,index)  in clientData.win_list" :style="{'margin-bottom': (index+1) == clientData.win_list.length?'0':'10px'}">
                <div style="display: flex;align-items: center">
                  <img style="width: 40px;height: 40px;margin-right: 8px" :src="item.prizeImage || item.receiveQr">
                  <div>
                    <div class="lottery-history-title">{{ item.prizeName }}</div>
                    <div class="lottery-history-time">{{item.createdAt}}</div>
                  </div>
                </div>
                <div class="change-btn" style="background: linear-gradient(-45deg, #FB5B3A, #FFAC6F);"@click="receiveReward(item)"
                     v-if="item.receiveStatus==0">
                  立即兑换
                </div>
                <div class="change-btn" style="background: linear-gradient(-45deg, #C4C2C7, #BAB9BB);" @click="receiveReward(item)" v-else>
                  已兑换
                </div>
              </div>
            </div>
          </div>
          <div class="lottery-btm-bottom" style="margin-top: -3px">
          </div>
        </div>
      </div>
    </div>

    <explain ref="explain"/>
    <prize ref="prize"/>
    <no-prize ref="noprize"></no-prize>
  </div>
</template>

<script>
import {contactDataApi, openUserInfoApi, contactLotteryApi, receiveApi} from "@/api/lottery";
import explain from "@/views/lottery/explain";
import prize from "@/views/lottery/prize";
import noPrize from "@/views/lottery/noPrize";

export default {
  components: {
    explain,
    prize,
    noPrize
  },
  data() {
    return {
      url: window.location.href,
      //  抽奖
      luckDraw: {
        //轮盘数据
        prizes: [],
        defaultConfig: {//默认样式
          gutter: '5px',//扇形之间的缝隙（默认等于 0无间隙）
          offsetDegree: 45
        },
        blocks: [//背景样式修改
          {
            padding: '27px',
            imgs: [
              {
                src: require("../../static/images/003.png"), // 转盘底图
                width: "100%",
                rotate: false
              }
            ]
          },
        ],
        buttons: [
          {radius: '28px', background: '#FFFFFF', pointer: true},
          {
            imgs: [
              {radius: '28px', background: '#FFFFFF', pointer: true},
              {
                src: require("../../static/images/lottery_btn.png"), // 转盘底图
                top: "-65px",
                width: "92.5px",
                height: "120.5px"
              }
            ]
          },
        ]
      },
      //  用户微信信息
      weChatUserNews: {},
      //  客户数据
      clientData: {
        win_list:[]
      },
      corp: {
      },
      //  设置的奖品
      prizeSetNews: []
    }
  },
  created() {
    //  获取参数
    this.id = this.$route.query.id;
    this.source = this.$route.query.source;
    this.getOpenUserInfo();
  },
  methods: {
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        id: that.id
      }).then((res) => {
        if (res.data.openid === undefined) {
            let redirectUrl = '/auth/lottery?id='+that.id+'&target=' + encodeURIComponent(that.url);
            that.$redirectAuth(redirectUrl);
            return;
        }
        this.weChatUserNews = res.data;
        this.getClientData();
      });
    },
    //  领取奖励
    receiveReward(item) {
      if (item.receiveStatus == 0) {
        receiveApi({
          id: item.id
        }).then((res) => {
          this.$message.success('奖励领取成功')
          this.getClientData()
        })
      }
      this.$refs.prize.show(item)
    },
    //  获取客户数据
    getClientData() {
      let params = {
        id: this.id,
        union_id: this.weChatUserNews.unionid,
        nickname: this.weChatUserNews.nickname,
        avatar: this.weChatUserNews.headimgurl,
        city: this.weChatUserNews.city,
        source: this.source
      }
      contactDataApi(params).then((res) => {
        document.title = res.data.lottery.name
        this.clientData = res.data
        res.data.prize.prizeSet.forEach((item, index) => {
          this.clientData.win_list.forEach((obj, idx) => {
            if (item.name == obj.prizeName) {
              if (item.type == 2) {
                obj.receiveQr = item.image
              }
            }
          })
        })
        this.corp = res.data.corp
        //  处理奖品设置数据
        this.prizeSetNews = this.clientData.prize.prizeSet
        this.handSetprize(this.prizeSetNews)
      })
    },
    //处理奖品设置数据
    handSetprize(data) {
      this.luckDraw.prizes = []
      data.forEach((item, index) => {
        let prizeNews = {
          name: "",
          background: "#fef2cb",
          fonts: [
            {
              text: "",
              fontSize: '12px',
              fontColor: '#DC2224',
              fontWeight: 'bold',
              top: 12
            }
          ],
          imgs: [{
            src: '', // 奖品图片
            width: "40%",
            top: 30,
            rotate: true
          }]
        }
        prizeNews.name = item.name
        prizeNews.fonts[0].text = item.name
        prizeNews.imgs[0].src = item.image
        this.luckDraw.prizes.push(prizeNews)
      })
    },
    //  开始抽奖
    startCallBack() {
      contactLotteryApi({
        id: this.id,
        union_id: this.weChatUserNews.unionid,
        nickname: this.weChatUserNews.nickname
      }).then((res) => {
        let prizeInfo = {}
        //在哪里停止
        this.prizeSetNews.forEach((item, index) => {
          if (item.name == res.data.prize_name) {
            prizeInfo = item
            this.$refs.LuckyWheel.play()
            setTimeout(() => {
              // 索引值
              this.$refs.LuckyWheel.stop(index)
            }, 3000)
          }
        })
        //弹窗提醒
        setTimeout(() => {
          let prizeData = {
            receiveName: res.data.prize_name,
            receiveCode: res.data.receive_code,
            receiveQr: res.data.receive_qr,
            description: res.data.description,
            nickname: this.weChatUserNews.nickname,
            headimgurl: this.weChatUserNews.headimgurl
          }
          if (prizeInfo.type == 2) {
            prizeData.receiveQr = prizeInfo.image
          }
          if (res.data.prize_name == '谢谢参与') {
            this.$refs.noprize.show()
          } else {
            this.$refs.prize.show(prizeData)
          }
          // this.$refs.prize.show(prizeData)
          this.getClientData()
        }, 6000)
      })
    }
  }
}
</script>

<style scoped lang="less">
.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #B00829 url("../../static/images/bg1.png") no-repeat;
  background-attachment: local;
  background-size: 100% auto;
  position: relative;
  overflow-y: auto;
  padding-bottom: 17px;

  .explain {
    position: absolute;
    padding: 6px 8px;
    right: 0;
    display: flex;
    align-items: center;
    top: 81px;
    font-size: 14px;
    color: #333333;
    background-color: #FFFFFF;
    border-radius: 16px 0px 0px 16px;
  }

  .prize_name {
    width: 80vw;
    height: 27px;
    border-radius: 27px;
    background: #DD6D17;
    line-height: 27px;
    color: #fff;
    padding-left: 12px;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    span {
      font-weight: bold;
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  .lottery-draw {
    display: flex;
    justify-content: center;

    .lottery-box {
      margin-top: 72.5px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .lottery-title {
        text-align: center;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        text-shadow: 0px 1px 0px #F0212B;
      }

      .lottery-desc {
        width:270px;
        text-align: center;
        font-size: 16px;
        color: #333333;
        max-width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        text-shadow: 0px 1px 0px #F0212B;
      }

      .lottery-namelist {
        width: 317px;
        height: 55.5px;
        margin-top: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../../static/images/lottery_name_bg.png");
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;


        .lottery-name {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FF4614;
          max-width: 280px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0 auto;
        }
      }

      .lottery-btm-top {
        width: 100%;
        height: 36px;
        margin-top: 18px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../static/images/btm-top.png");
      }

      .lottery-btm-bottom {
        width: 100%;
        height: 36px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../static/images/btm-bottom.png");
      }

      .lottery-btm {
        width: 100%;
        height: auto;
        //margin-top: -20px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../static/images/btm-center.png");

        .lottery-history-item {
          width: 281px;
          height: auto;
          background: #FFFFFF;
          border-radius: 6px;
          margin: 0 auto;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .lottery-history-title {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -moz-box;
            -moz-line-clamp: 2;
            -moz-box-orient: vertical;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;
          }

          .lottery-history-time {
            font-size: 10px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }

          .change-btn {
            width: 66.5px;
            height: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 18.5px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }

      .top {
        background-color: #f8bf65;
        width: 78vw;
        height: 45px;
        margin-top: 12px;
        border-radius: 4px;
        display: flex;
        box-shadow: 0 0 5px #D19275 inset;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        color: maroon;

        .logo {
          margin: 0 12px;
        }
      }

      .bottom {
        width: 78vw;
        height: 300px;
        display: block;
        position: relative;
        z-index: 10;
        background: linear-gradient(to top, #ffd38f, #f48744);
        border-radius: 18px;

        .box {
          width: 75vw;
          height: 275px;
          background-color: #fff;
          border-radius: 18px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }


  }

  .record {
    display: flex;
    justify-content: center;

    .lottery-box {
      width: 88vw;
      height: 430px;
      background: linear-gradient(#fff4a4, #faae7c);
      border-radius: 18px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .top {
        text-align: center;
        width: 78vw;
        height: 45px;
        margin-top: 12px;

        p {
          color: #e3453a;
          font-size: 18px;
          font-weight: bold;
        }

        span {
          display: block;
          margin-top: -16px;
        }
      }

      .bottom {
        width: 78vw;
        height: 320px;
        display: block;
        position: relative;
        z-index: 10;
        background: linear-gradient(to top, #f48744, #ffd38f);
        border-radius: 18px;

        .box {
          width: 75vw;
          height: 280px;
          background-color: #fff;
          border-radius: 18px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 12px 16px 10px 16px;
          overflow-y: scroll;
        }
      }
    }
  }
}

.draw_list {
  margin-top: 10px;
}

.draw_list:first-child {
  margin-top: 0;
}

.converted {
  width: 66px;
  text-align: center;
  background: #F5F5F5;
  color: #B8B8B8;
  border: 1px solid #D9D9D9;
}

.exchange-box {
  display: flex;
  padding: 10px;
  background-color: #fff2a4;
  border-radius: 18px;
  margin-top: 3px;
  align-items: center;

  .exchange-name {
    flex: 1;

    span {
      margin-left: 6px;
    }
  }
}
</style>
